<template>
  <div class="mt-10">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Total Nilai SPP</label>
        <v-money class="w-full" :value="spkData.header.nilai_total - totalNilaiDikurangi" readonly/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">DPP</label>
        <v-money class="w-full" :value="spkData.header.dpp - totalDppDikurangi" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPN Jenis</label>
        <vs-input class="w-full" :value="spkData.header.ppn_persen > 0 ? 'Dengan PPN' : 'Tanpa PPN'" readonly/>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPN</label>
        <vs-input class="w-full" :value="`${idr(spkData.header.ppn_jml - totalPpnDikurangi)} (${spkData.header.ppn_persen}%)`" readonly/>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPh Jenis</label>
        <vs-input class="w-full" :value="spkData.header.pph_persen > 0 ? spkData.header.pph_nama : '-'" readonly/>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPh</label>
        <vs-input class="w-full" :value="`${idr(spkData.header.pph_jml - totalPphDikurangi)} (${spkData.header.pph_persen}%)`" readonly/>
      </div>
    </div>

    <!--termin-->
    <vs-divider class="mt-base">Detail Termin</vs-divider>
    <vs-table :data="spkData.pembayaran" stripe>
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">Status</vs-th>
        <vs-th class="whitespace-no-wrap">No Termin</vs-th>
        <vs-th class="whitespace-no-wrap">Tgl Due</vs-th>
        <vs-th class="whitespace-no-wrap">Retensi</vs-th>
        <vs-th class="whitespace-no-wrap">Progress %</vs-th>
        <vs-th class="whitespace-no-wrap">Persen Bayar</vs-th>
        <vs-th class="whitespace-no-wrap">Jumlah</vs-th>
        <vs-th class="whitespace-no-wrap">DPP</vs-th>
        <vs-th class="whitespace-no-wrap">PPN</vs-th>
        <vs-th class="whitespace-no-wrap">PPh</vs-th>
        <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
        <vs-th class="whitespace-no-wrap">Status Approval</vs-th>
        <vs-th class="whitespace-no-wrap">Approval Progress</vs-th>
        <vs-th class="whitespace-no-wrap">File Pengajuan</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
          <vs-td class="whitespace-no-wrap">
            <div class="flex items-center">
              <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="'bg-' + item.status_color">
                <span class="text-xs">{{ item.status }}</span>
              </div>
            </div>
          </vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.no_termin }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.tgl_due }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.is_retention ? 'YES' : '-' }}</vs-td>
          <vs-td class="text-right">{{ item.bobot | idr }}%</vs-td>
          <vs-td class="text-right">{{ item.persen | idr }}%</vs-td>
          <vs-td class="text-right">{{ (item.jml_total - getPenguranganTermin(item.id).total_nilai) | idr }}</vs-td>
          <vs-td class="text-right">{{ (item.dpp - getPenguranganTermin(item.id).total_dpp) | idr }}</vs-td>
          <vs-td class="text-right">{{ (item.ppn_jml - getPenguranganTermin(item.id).total_ppn) | idr }}</vs-td>
          <vs-td class="text-right">{{ (item.pph_jml - getPenguranganTermin(item.id).total_pph) | idr }}</vs-td>
          <vs-td class="">{{ item.keterangan || '-' }}</vs-td>
          <vs-td class="whitespace-no-wrap">
            <span v-if="item.status === 'UNPAID' || spkData.header.jenis_spk === 'OTHER'">-</span>
            <div v-else class="flex items-center">
              <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="'bg-' + item.status_approval_color">
                <span class="text-xs">{{ item.status_approval }}</span>
              </div>
            </div>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <span v-if="item.status === 'UNPAID' || spkData.header.jenis_spk === 'OTHER'">-</span>
            <span v-else class="underline cursor-pointer" @click="showModalApproval(item)">Lihat Progress</span>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <span v-if="item.list_file_pengajuan.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item.list_file_pengajuan)">Lihat Files</span>
            <span v-else class="text-sm">-</span>
          </vs-td>
        </vs-tr>
        <!--footer-->
        <vs-tr class="text-sm font-bold">
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td class="text-right">{{ summary.grandTotalPersen | idr }}%</vs-td>
          <vs-td class="text-right">{{ summary.grandTotalNilai | idr }}</vs-td>
          <vs-td class="text-right">{{ summary.grandTotalDpp | idr }}</vs-td>
          <vs-td class="text-right">{{ summary.grandTotalPpn | idr }}</vs-td>
          <vs-td class="text-right">{{ summary.grandTotalPph | idr }}</vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!--modals-->
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
  </div>
</template>

<script>
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'

export default {
  name: 'TabPembayaran',
  props: {
    isActive: { type: Boolean }
  },
  components: {
    VMoney,
    FilesViewer,
    ModalApprovalProgress: () => import('@/views/components/approval-progress/ModalApprovalProgress')
  },
  computed: {
    storeSpkShow () {
      return this.$store.state.proyek.spkShow
    },
    spkData () {
      return this.storeSpkShow.spkData
    },
    listAdjust () {
      return this.spkData.adjust
    },
    totalDppDikurangi () {
      return _.sumBy(this.listAdjust, item => item.total_dpp)
    },
    totalPpnDikurangi () {
      return _.sumBy(this.listAdjust, item => item.total_jml_nilai_ppn)
    },
    totalNilaiDikurangi () {
      return _.sumBy(this.listAdjust, item => item.total_jml_nilai)
    },
    totalPphDikurangi () {
      return _.sumBy(this.listAdjust, item => item.total_jml_nilai_pph)
    },
    totalNominalDikurangi () {
      return _.sumBy(this.listAdjust, item => item.total_jml_nilai_pokok)
    },
    summary () {
      return {
        grandTotalPersen: _.sumBy(this.spkData.pembayaran, item => item.persen),
        grandTotalNilai: _.sumBy(this.spkData.pembayaran, item => item.jml_total) - this.totalNilaiDikurangi,
        grandTotalDpp: _.sumBy(this.spkData.pembayaran, item => item.dpp) - this.totalDppDikurangi,
        grandTotalPpn: _.sumBy(this.spkData.pembayaran, item => item.ppn_jml) - this.totalPpnDikurangi,
        grandTotalPph: _.sumBy(this.spkData.pembayaran, item => item.pph_jml) - this.totalPphDikurangi
      }
    }
  },
  data () {
    return {
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      },
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  methods: {
    showModalApproval (item) {
      this.modalApproval.type = 'SPP-TERMIN'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    },

    getPenguranganTermin (idTermin) {
      const items = _.filter(this.listAdjust, item => item.id_spk_d === idTermin)
      return {
        total_dpp: _.sumBy(items, item => item.total_dpp),
        total_ppn: _.sumBy(items, item => item.total_jml_nilai_ppn),
        total_nilai: _.sumBy(items, item => item.total_jml_nilai),
        total_pph: _.sumBy(items, item => item.total_jml_nilai_pph),
        total_nominal: _.sumBy(items, item => item.total_jml_nilai_pokok)
      }
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    showFiles (urls) {
      this.modalFiles.filesUrl = urls
      this.modalFiles.active = true
    }
  }
}
</script>
